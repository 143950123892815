import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const BillingSummaryColumn = (data)=>{
    
    if(data.length==0)
    {
        return [
            { dataField: 'hc', text: 'HC', hidden:  true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'invoiceNo', text: 'INVOICE NO',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'prfNo', text: 'PRF #', hidden:  true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'employeeName', text: 'EMPLOYEE NAME', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px'}
            },
            // { dataField: 'costCenter', text: 'COST CENTER',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'}
            // },
            { dataField: 'location', text: 'STORE NAME', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px'}
            },
            { dataField: 'regularRate', text: 'REGULAR BILLING RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'regularHrs', text: 'REGULAR HRS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'regularAmount', text: 'REGULAR AMOUNT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiumRate', text: 'PREMIUM BILLING RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            // { dataField: 'premiuM_OT_HOURS', text: 'OT HRS',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },
            // { dataField: 'premiuM_OT', text: 'OT AMOUNT',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },   
            // { dataField: 'premiuM_ND_HOURS', text: 'ND HRS',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },
            // { dataField: 'premiuM_ND', text: 'ND AMOUNT',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },  
            { dataField: 'subTotal', text: 'SUB TOTAL',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'vat', text: 'VAT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'grandTotal', text: 'GRAND TOTAL',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'remarks', text: 'REMARKS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'9%',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px'}
            }]
    }
    else
    {
        return [
            { dataField: 'hc', text: 'HC', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'},
                csvExport: true,
            },
            { dataField: 'prfNo', text: 'PRF #', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'invoiceNo', text: data[0].isSI==="0" ? "BS #" : "SI #",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',whiteSpace:'nowrap',fontSize:'10px'}
            },
            { dataField: 'clientName', text: 'CLIENT NAME', hidden: true,
                csvExport: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'period', text: 'PERIOD', hidden: true,
                csvExport: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'employeeNo', text: 'EMPLOYEE NO', hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'employeeName', text: 'EMPLOYEE NAME', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'costCenter', text: 'COST CENTER',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'location', text: 'STORE NAME', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'position', text: 'POSITION', hidden:  false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'rateName', text: 'TRIP RATE', hidden:  data[0].rateName==="" ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%',fontSize:'10px',whiteSpace:'nowrap' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'},
                csvExport: true,
            },
            { dataField: 'salaryRate', text: 'RATE',hidden: parseFloat(data[data.length-1].salaryRate)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].salaryRate)===0 ? false : true,
            },
            { dataField: 'colaRate', text: 'COLA RATE', hidden: parseFloat(data[data.length-1].colaRate)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].colaRate)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'seaRate', text: 'SEA RATE', hidden: parseFloat(data[data.length-1].seaRate)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].seaRate)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'regularRate', text: 'REGULAR BILLING RATE',hidden: parseFloat(data[data.length-1].regularRate)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].regularRate)===0 ? false : true,
            },
            { dataField: 'totalTrip', text: 'No Of Trip',hidden: parseFloat(data[data.length-1].totalTrip)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'totalTripAmount', text: 'TRIP AMOUNT',hidden: parseFloat(data[data.length-1].totalTripAmount)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'basicPayDays', text: 'BASIC PAY DAYS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                //csvExport: parseFloat(data[data.length-1].basicPayDays)===0 ? false : true,
            },
            { dataField: 'regularHrs', text: 'BASIC PAY HRS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                //csvExport: parseFloat(data[data.length-1].regularHrs)===0 ? false : true,
            },
            { dataField: 'basicPayAmount', text: 'BASIC PAY AMOUNT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                //csvExport: parseFloat(data[data.length-1].regularAmount)===0 ? false : true,
            },
            { dataField: 'colaAmount', text: 'COLA AMOUNT',hidden: parseFloat(data[data.length-1].colaAmount)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].colaAmount)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'seaAmount', text: 'SEA AMOUNT',hidden: parseFloat(data[data.length-1].seaAmount)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].seaAmount)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'lateMins', text: 'LATE MINS',hidden: parseFloat(data[data.length-1].lateMins)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].lateMins)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'lateAmount', text: 'LATE AMOUNT',hidden: parseFloat(data[data.length-1].lateAmount)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].lateAmount)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'undertimeMins', text: 'UT MINS',hidden: parseFloat(data[data.length-1].undertimeMins)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].undertimeMins)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'undertimeAmount', text: 'UT AMOUNT',hidden: parseFloat(data[data.length-1].undertimeAmount)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].undertimeAmount)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'absentDays', text: 'ABSENT DAYS',hidden: parseFloat(data[data.length-1].absentDays)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].absentDays)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'absentAmount', text: 'ABSENT AMOUNT',hidden: parseFloat(data[data.length-1].absentAmount)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].absentAmount)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'netRegularPay', text: 'NET REGULAR PAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiumRate', text: 'PREMIUM BILLING RATE',hidden: parseFloat(data[data.length-1].premiumRate)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].premiumRate)===0 ? false : true,
            },
            { dataField: 'premiuM_OT_HOURS', text: 'OT HRS',hidden: parseFloat(data[data.length-1].premiuM_OT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_OT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_OT', text: 'OT AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_OT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_OT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },   
            { dataField: 'premiuM_ND_HOURS', text: 'ND HRS',hidden: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_ND', text: 'ND AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_ND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_ND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },   
            { dataField: 'premiuM_NDOT_HOURS', text: 'ND OT HRS',hidden: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_NDOT', text: 'ND OT AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_NDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_NDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },   
            
            { dataField: 'premiuM_DOD_HOURS', text: 'RESTDAY HRS',hidden: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_DOD', text: 'REST DAY',hidden: parseFloat(data[data.length-1].premiuM_DOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODOT_HOURS', text: 'REST DAY OT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODOT', text: 'REST DAY OT',hidden: parseFloat(data[data.length-1].premiuM_DODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODND_HOURS', text: 'REST DAY ND HRS',hidden: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODND', text: 'REST DAY ND',hidden: parseFloat(data[data.length-1].premiuM_DODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODNDOT_HOURS', text: 'REST DAY NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODNDOT', text: 'REST DAY NDOT',hidden: parseFloat(data[data.length-1].premiuM_DODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SHOL_HOURS', text: 'SHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOL', text: 'SHOL',hidden: parseFloat(data[data.length-1].premiuM_SHOL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLOT_HOURS', text: 'SHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLOT', text: 'SHOL OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLND_HOURS', text: 'SHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLND', text: 'SHOL ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLNDOT_HOURS', text: 'SHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLNDOT', text: 'SHOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SHOLDOD_HOURS', text: 'SHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDOD', text: 'SHOL DOD',hidden: parseFloat(data[data.length-1].premiuM_SHOLDOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODOT_HOURS', text: 'SHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODOT', text: 'SHOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODND_HOURS', text: 'SHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODND', text: 'SHOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODNDOT_HOURS', text: 'SHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODNDOT', text: 'SHOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOL_UNWORK_HOURS', text: 'LHOL UNWORK HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOL_UNWORK_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOL_UNWORK_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOL_UNWORK', text: 'LHOL UNWORK',hidden: parseFloat(data[data.length-1].premiuM_LHOL_UNWORK)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOL_UNWORK)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOL_HOURS', text: 'LHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOL', text: 'LHOL',hidden: parseFloat(data[data.length-1].premiuM_LHOL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLOT_HOURS', text: 'LHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLOT', text: 'LHOL OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLND_HOURS', text: 'LHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLND', text: 'LHOL ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLNDOT_HOURS', text: 'LHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
                { dataField: 'premiuM_LHOLNDOT', text: 'LHOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLDOD_HOURS', text: 'LHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDOD', text: 'LHOL DOD',hidden: parseFloat(data[data.length-1].premiuM_LHOLDOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODOT_HOURS', text: 'LHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODOT', text: 'LHOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODND_HOURS', text: 'LHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODND', text: 'LHOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODNDOT_HOURS', text: 'LHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODNDOT', text: 'LHOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //START OF DOUBLE LEGAL
            { dataField: 'premiuM_LHOLLHOL_HOURS', text: 'DOUBLE LEGAL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOL', text: 'DOUBLE LEGAL',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLOT_HOURS', text: 'DOUBLE LEGAL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLOT', text: 'DOUBLE LEGAL OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLND_HOURS', text: 'DOUBLE LEGAL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLND', text: 'DOUBLE LEGAL ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLNDOT_HOURS', text: 'DOUBLE LEGAL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLNDOT', text: 'DOUBLE LEGAL NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLLHOLDOD_HOURS', text: 'DOUBLE LEGAL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDOD', text: 'DOUBLE LEGAL DOD',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODOT_HOURS', text: 'DOUBLE LEGAL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODOT', text: 'DOUBLE LEGAL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODND_HOURS', text: 'DOUBLE LEGAL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODND', text: 'DOUBLE LEGAL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODNDOT_HOURS', text: 'DOUBLE LEGAL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODNDOT', text: 'DOUBLE LEGAL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //END OF DOUBLE LEGAL

            //START OF DOUBLE HOL
            { dataField: 'premiuM_LHOLSHOL_HOURS', text: 'DOUBLE HOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOL', text: 'DOUBLE HOL',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLOT_HOURS', text: 'DOUBLE HOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLOT', text: 'DOUBLE HOL OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLND_HOURS', text: 'DOUBLE HOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLND', text: 'DOUBLE HOL ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLNDOT_HOURS', text: 'DOUBLE HOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLNDOT', text: 'DOUBLE HOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLSHOLDOD_HOURS', text: 'DOUBLE HOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDOD', text: 'DOUBLE HOL DOD',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODOT_HOURS', text: 'DOUBLE HOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODOT', text: 'DOUBLE HOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODND_HOURS', text: 'DOUBLE HOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODND', text: 'DOUBLE HOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODNDOT_HOURS', text: 'DOUBLE HOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODNDOT', text: 'DOUBLE HOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //END OF DOUBLE HOL
            { dataField: 'premiuM_SHOLSHOL_HOURS', text: 'DOUBLE SH HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOL', text: 'DOUBLE SH',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLOT_HOURS', text: 'DOUBLE SH OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLOT', text: 'DOUBLE SH OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLND_HOURS', text: 'DOUBLE SH ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLND', text: 'DOUBLE SH ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLNDOT_HOURS', text: 'DOUBLE SH NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLNDOT', text: 'DOUBLE SH NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SHOLSHOLDOD_HOURS', text: 'DOUBLE SH DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDOD', text: 'DOUBLE SH DOD',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDOD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDOD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODOT_HOURS', text: 'DOUBLE SH DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODOT', text: 'DOUBLE SH DOD OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODND_HOURS', text: 'DOUBLE SH DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODND', text: 'DOUBLE SH DOD ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODNDOT_HOURS', text: 'DOUBLE SH DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLSHOLDODNDOT', text: 'DOUBLE SH DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODNDOT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLSHOLDODNDOT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_VACATIONLEAVE_HOURS', text: 'VACATION LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_VACATIONLEAVE', text: 'VACATION LEAVE',hidden: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SICKLEAVE_HOURS', text: 'SICK LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SICKLEAVE', text: 'SICK LEAVE',hidden: parseFloat(data[data.length-1].premiuM_SICKLEAVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SICKLEAVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_PATERNITYLEAVE_HOURS', text: 'PATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_PATERNITYLEAVE', text: 'PATERNITY LEAVE',hidden: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_MATERNITYLEAVE_HOURS', text: 'MATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_MATERNITYLEAVE', text: 'MATERNITY LEAVE',hidden: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
	    
	        { dataField: 'premiuM_SPLLEAVE_HOURS', text: 'SOLO PARENTAL LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'premiuM_SPLLEAVE', text: 'SOLO PARENTAL LEAVE',hidden: parseFloat(data[data.length-1].premiuM_SPLLEAVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SPLLEAVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },   
            // { dataField: 'premiuM_OT_HOURS', text: 'OT HRS',hidden: parseFloat(data[data.length-1].premiuM_OT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_OT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_OT', text: 'OT AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_OT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_OT)===0 ? false : true,
            // },   
            // { dataField: 'premiuM_ND_HOURS', text: 'ND HRS',hidden: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_ND', text: 'ND AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_ND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_ND)===0 ? false : true,
            // },  
            // { dataField: 'premiuM_NDOT_HOURS', text: 'ND OT HRS',hidden: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_NDOT', text: 'ND OT AMOUNT',hidden: parseFloat(data[data.length-1].premiuM_NDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_NDOT)===0 ? false : true,
            // },   
            
            // { dataField: 'premiuM_DOD_HOURS', text: 'RESTDAY HRS',hidden: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DOD', text: 'REST DAY',hidden: parseFloat(data[data.length-1].premiuM_DOD)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DOD)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODOT_HOURS', text: 'REST DAY OT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODOT', text: 'REST DAY OT',hidden: parseFloat(data[data.length-1].premiuM_DODOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODND_HOURS', text: 'REST DAY ND HRS',hidden: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODND', text: 'REST DAY ND',hidden: parseFloat(data[data.length-1].premiuM_DODND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODNDOT_HOURS', text: 'REST DAY NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_DODNDOT', text: 'REST DAY NDOT',hidden: parseFloat(data[data.length-1].premiuM_DODNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_DODNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_SHOL_HOURS', text: 'SHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOL', text: 'SHOL',hidden: parseFloat(data[data.length-1].premiuM_SHOL)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOL)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLOT_HOURS', text: 'SHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLOT', text: 'SHOL OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLND_HOURS', text: 'SHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLND', text: 'SHOL ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLNDOT_HOURS', text: 'SHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLNDOT', text: 'SHOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_SHOLDOD_HOURS', text: 'SHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDOD', text: 'SHOL DOD',hidden: parseFloat(data[data.length-1].premiuM_SHOLDOD)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDOD)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODOT_HOURS', text: 'SHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODOT', text: 'SHOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODND_HOURS', text: 'SHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODND', text: 'SHOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODNDOT_HOURS', text: 'SHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SHOLDODNDOT', text: 'SHOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_LHOL_HOURS', text: 'LHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOL', text: 'LHOL',hidden: parseFloat(data[data.length-1].premiuM_LHOL)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOL)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLOT_HOURS', text: 'LHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLOT', text: 'LHOL OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLND_HOURS', text: 'LHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLND', text: 'LHOL ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLNDOT_HOURS', text: 'LHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? false : true,
            // },
            //     { dataField: 'premiuM_LHOLNDOT', text: 'LHOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_LHOLDOD_HOURS', text: 'LHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDOD', text: 'LHOL DOD',hidden: parseFloat(data[data.length-1].premiuM_LHOLDOD)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDOD)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODOT_HOURS', text: 'LHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODOT', text: 'LHOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODND_HOURS', text: 'LHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODND', text: 'LHOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODNDOT_HOURS', text: 'LHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLDODNDOT', text: 'LHOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_LHOLSHOL_HOURS', text: 'LHOL SHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOL', text: 'LHOL SHOL',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOL)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOL)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLOT_HOURS', text: 'LHOL SHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLOT', text: 'LHOL SHOL OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLND_HOURS', text: 'LHOL SHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLND', text: 'LHOL SHOL ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLNDOT_HOURS', text: 'LHOL SHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLNDOT', text: 'LHOL SHOL NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_LHOLSHOLDOD_HOURS', text: 'LHOL SHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDOD', text: 'LHOL SHOL DOD',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODOT_HOURS', text: 'LHOL SHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODOT', text: 'LHOL SHOL DOD OT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODND_HOURS', text: 'LHOL SHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODND', text: 'LHOL SHOL DOD ND',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODNDOT_HOURS', text: 'LHOL SHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_LHOLSHOLDODNDOT', text: 'LHOL SHOL DOD NDOT',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT)===0 ? false : true,
            // },

            // { dataField: 'premiuM_VACATIONLEAVE_HOURS', text: 'VACATION LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_VACATIONLEAVE', text: 'VACATION LEAVE',hidden: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SICKLEAVE_HOURS', text: 'SICK LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'center',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SICKLEAVE', text: 'SICK LEAVE',hidden: parseFloat(data[data.length-1].premiuM_SICKLEAVE)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_SICKLEAVE)===0 ? false : true,
            // },
            // { dataField: 'premiuM_PATERNITYLEAVE_HOURS', text: 'PATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_PATERNITYLEAVE', text: 'PATERNITY LEAVE',hidden: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE)===0 ? false : true,
            // },
            // { dataField: 'premiuM_MATERNITYLEAVE_HOURS', text: 'MATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? false : true,
            // },
            // { dataField: 'premiuM_MATERNITYLEAVE', text: 'MATERNITY LEAVE',hidden: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE)===0 ? true : false,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'},
            //     csvExport: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE)===0 ? false : true,
            // },
            // { dataField: 'premiuM_SPLLEAVE_HOURS', text: 'SOLO PARENTAL LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? true : false,
            //     csvExport: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? false : true,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },
            // { dataField: 'premiuM_SPLLEAVE', text: 'SOLO PARENTAL LEAVE',hidden: parseFloat(data[data.length-1].premiuM_SPLLEAVE)===0 ? true : false,
            //     csvExport: parseFloat(data[data.length-1].premiuM_SPLLEAVE)===0 ? false : true,
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },
            { dataField: 'totalPremiums', text: 'TOTAL PREMIUMS',hidden: parseFloat(data[data.length-1].totalPremiums)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].totalPremiums)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'grossPay', text: 'GROSS PAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: true,
            },

            { dataField: 'inclusioN_SPF_NOOFDAYS', text: 'SKILLS PAY FX NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_SPF_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SPF_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SPF_AMOUNT', text: 'SKILLS PAY FX',hidden: parseFloat(data[data.length-1].inclusioN_SPF_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SPF_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_MA_NOOFDAYS', text: 'MEAL ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_MA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_MA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MA_AMOUNT', text: 'MEAL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_MA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_MA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioN_DA_NOOFDAYS', text: 'ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_DA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_DA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DA_AMOUNT', text: 'ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_DA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_DA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
        
            { dataField: 'inclusioN_SPA_NOOFDAYS', text: 'SKILLS PAY ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_SPA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SPA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SPA_AMOUNT', text: 'SKILLS PAY ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SPA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SPA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_TL_NOOFDAYS', text: 'TEAM LEADER ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_TL_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_TL_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_TL_AMOUNT', text: 'TEAM LEADER ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_TL_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_TL_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_HP_NOOFDAYS', text: 'HAZARD PAY NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_HP_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_HP_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioN_HP_AMOUNT', text: 'HAZARD PAY',hidden: parseFloat(data[data.length-1].inclusioN_HP_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_HP_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ALLOWANCE_AMOUNT', text: 'ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_ALLOWANCE_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_ALLOWANCE_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SL_AMOUNT', text: 'SHIFT LEADER',hidden: parseFloat(data[data.length-1].inclusioN_SL_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SL_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_GM_AMOUNT', text: 'GROSS MARGIN',hidden: parseFloat(data[data.length-1].inclusioN_GM_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_GM_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SG_AMOUNT', text: 'SALES GROWTH',hidden: parseFloat(data[data.length-1].inclusioN_SG_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SG_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_PA_AMOUNT', text: 'PERFECT ATTENDANCE',hidden: parseFloat(data[data.length-1].inclusioN_PA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_PA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LBC_AMOUNT', text: 'LBC',hidden: parseFloat(data[data.length-1].inclusioN_LBC_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_LBC_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_BDAY_AMOUNT', text: 'BIRTHDAY',hidden: parseFloat(data[data.length-1].inclusioN_BDAY_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_BDAY_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_TRANSPO_AMOUNT', text: 'TRANSPORTATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_TRANSPO_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_TRANSPO_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_WER_AMOUNT', text: 'WEEKLY EXPENSE REPORT',hidden: parseFloat(data[data.length-1].inclusioN_WER_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_WER_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LOAD_AMOUNT', text: 'LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LOAD_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_LOAD_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MRP_AMOUNT', text: 'MRP INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioN_MRP_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_MRP_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_OPUP_ADJ', text: 'ADJ (OP / UP)',hidden: parseFloat(data[data.length-1].inclusioN_OPUP_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_OPUP_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SERVICE_CHARGED', text: 'SERVICE CHARGE',hidden: parseFloat(data[data.length-1].inclusioN_SERVICE_CHARGED)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SERVICE_CHARGED)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_COOP_LOAN', text: 'ADDBACK COOP LOAN',hidden: parseFloat(data[data.length-1].inclusioN_COOP_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_COOP_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SSS_LOAN', text: 'ADDBACK SSS LOAN',hidden: parseFloat(data[data.length-1].inclusioN_SSS_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SSS_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_HDMF_LOAN', text: 'ADDBACK HDMF LOAN',hidden: parseFloat(data[data.length-1].inclusioN_HDMF_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_HDMF_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SETUP_ALLOWANCE', text: 'SET UP ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SETUP_ALLOWANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SETUP_ALLOWANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_RATE_ADJUSTMENT', text: 'RATE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioN_RATE_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_RATE_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_UNDER_PAYMENT', text: 'UNDER PAYMENT (FOR BILLING)',hidden: parseFloat(data[data.length-1].inclusioN_UNDER_PAYMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_UNDER_PAYMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_UNDER_PAYMENT1', text: 'UNDER PAYMENT (NOT FOR BILLING)',hidden: parseFloat(data[data.length-1].inclusioN_UNDER_PAYMENT1)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_UNDER_PAYMENT1)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_CREDITBREAD', text: 'ADDBACK CREDIT BREAD',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_CREDITBREAD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_CREDITBREAD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_CREDITPORK', text: 'ADDBACK CREDIT PORK',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_CREDITPORK)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_CREDITPORK)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DIVIDEND_ADDPAYROLL', text: 'DIVIDEND (ADD TO PAYROLL)',hidden: parseFloat(data[data.length-1].inclusioN_DIVIDEND_ADDPAYROLL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_DIVIDEND_ADDPAYROLL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_13TH_ADDPAYROLL', text: '13TH MONTH (ADD TO PAYROLL)',hidden: parseFloat(data[data.length-1].inclusioN_13TH_ADDPAYROLL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_13TH_ADDPAYROLL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MC_RENTAL', text: 'MC RENTAL',hidden: parseFloat(data[data.length-1].inclusioN_MC_RENTAL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_MC_RENTAL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SA_INCENTIVE', text: 'SA INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioN_SA_INCENTIVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SA_INCENTIVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SENIORITY_PAY', text: 'SENIORITY PAY',hidden: parseFloat(data[data.length-1].inclusioN_SENIORITY_PAY)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioN_SENIORITY_PAY)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_RICE_SUBSIDY', text: 'RICE SUBSIDY',hidden: parseFloat(data[data.length-1].inclusioN_RICE_SUBSIDY)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_RICE_SUBSIDY)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioN_MC_RENTAL_ADJ', text: 'MC RENTAL ADJ',hidden: parseFloat(data[data.length-1].inclusioN_MC_RENTAL_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_MC_RENTAL_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MEAL_ALLOW_ADJ', text: 'MEAL ALLOWANCE ADJ',hidden: parseFloat(data[data.length-1].inclusioN_MEAL_ALLOW_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_MEAL_ALLOW_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_OT_ADJ', text: 'OT ADJ',hidden: parseFloat(data[data.length-1].inclusioN_OT_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_OT_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MH_ADJ', text: 'MH ADJ',hidden: parseFloat(data[data.length-1].inclusioN_MH_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_MH_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
	        { dataField: 'inclusioN_INCENTIVES', text: 'INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioN_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_ADDBACK_HMO', text: 'ADDBACK HMO',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_HMO)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_HMO)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_CBU', text: 'ADDBACK CBU',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_CBU)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_CBU)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_WHSE_INCENTIVES', text: 'WHSE INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioN_WHSE_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_WHSE_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_HOUSING_ALLOWANCE', text: 'HOUSING ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_HOUSING_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_HOUSING_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DATA_ALLOWANCE', text: 'DATA ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_DATA_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DATA_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_SSS', text: 'ADDBACK SSS',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_SSS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_SSS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_PHIC', text: 'ADDBACK PHIC',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_PHIC)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_PHIC)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_HDMF', text: 'ADDBACK HDMF',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_HDMF)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_HDMF)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_INCENTIVE_ADJ', text: 'INCENTIVE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioN_INCENTIVE_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_INCENTIVE_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SVC_INC_LEAVE', text: 'SERVICE INCENTIVE LEAVE',hidden: parseFloat(data[data.length-1].inclusioN_SVC_INC_LEAVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SVC_INC_LEAVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_13TH_MONTH', text: '13TH MONTH',hidden: parseFloat(data[data.length-1].inclusioN_13TH_MONTH)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_13TH_MONTH)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MC_PLAN', text: 'MC PLAN',hidden: parseFloat(data[data.length-1].inclusioN_MC_PLAN)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_MC_PLAN)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SPOT', text: 'SPOT & UAT OT',hidden: parseFloat(data[data.length-1].inclusioN_SPOT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SPOT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_RATE_ALLOWANCE', text: 'RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_OT_RATE_ALLOWANCE', text: 'OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DOD_RATE_ALLOWANCE', text: 'DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DOD_OT_RATE_ALLOWANCE', text: 'DOD OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_DOD_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DOD_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SH_RATE_ALLOWANCE', text: 'SH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LH_RATE_ALLOWANCE', text: 'LH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LHOT_RATE_ALLOWANCE', text: 'LHOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SHOT_RATE_ALLOWANCE', text: 'SHOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SHOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SHOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_PRODUCT_CONSULTANT_LOAD_ALLOWANCE', text: 'PRODUCT CONSULTANT LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_PRODUCT_CONSULTANT_LOAD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_PRODUCT_CONSULTANT_LOAD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SH_DOD_RATE_ALLOWANCE', text: 'SH DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SH_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SH_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SH_DODOT_RATE_ALLOWANCE', text: 'SH DODOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_SH_DODOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SH_DODOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LH_DOD_RATE_ALLOWANCE', text: 'LH DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LH_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LH_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LH_DODOT_RATE_ALLOWANCE', text: 'LH DODOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LH_DODOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LH_DODOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_TL_ALLOWANCE', text: 'NOT FOR BILLING - TL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_TL_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_TL_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_LOAD_ALLOWANCE', text: 'NOT FOR BILLING - LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_LOAD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_LOAD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_COMMUNICATION_ALLOWANCE', text: 'COMMUNICATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_COMMUNICATION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_COMMUNICATION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_13TH_MONTH_RATE_ALLOWANCE', text: '13TH MONTH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_13TH_MONTH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_13TH_MONTH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_PERFECT_ATTENDANCE', text: 'NOT FOR BILLING - PERFECT ATTENDANCE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_PERFECT_ATTENDANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_PERFECT_ATTENDANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE', text: 'NOT FOR BILLING - MEAL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS', text: 'NOT FOR BILLING - MEAL ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_14TH_MONTH', text: '14TH MONTH',hidden: parseFloat(data[data.length-1].inclusioN_14TH_MONTH)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_14TH_MONTH)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_CASH_ADVANCE', text: 'CASH ADVANCE',hidden: parseFloat(data[data.length-1].inclusioN_CASH_ADVANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_CASH_ADVANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_REVOLVING_FUND', text: 'REVOLVING FUND',hidden: parseFloat(data[data.length-1].inclusioN_REVOLVING_FUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_REVOLVING_FUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DIVIDEND', text: 'DIVIDEND',hidden: parseFloat(data[data.length-1].inclusioN_DIVIDEND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DIVIDEND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_OJT_AND_OBT', text: 'OJT AND OBT',hidden: parseFloat(data[data.length-1].inclusioN_OJT_AND_OBT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_OJT_AND_OBT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_PURCHASED_ITEM', text: 'ADDBACK PURCHASED ITEM',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_PURCHASED_ITEM)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_PURCHASED_ITEM)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_TRAVEL_FUND', text: 'ADDBACK TRAVEL FUND',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_TRAVEL_FUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_TRAVEL_FUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_LOSS_AND_DAMAGES', text: 'ADDBACK LOSS AND DAMAGES',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_LOSS_AND_DAMAGES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_LOSS_AND_DAMAGES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_MEMBERSHIP_FEE_AAP_NRF', text: 'ADDBACK MEMBERSHIP FEE(AAP/NRF)',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_MEMBERSHIP_FEE_AAP_NRF)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_MEMBERSHIP_FEE_AAP_NRF)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_MC_LOANS_PROCESSING_FEE', text: 'ADDBACK MC LOANS/PROCESSING FEE',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_MC_LOANS_PROCESSING_FEE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_MC_LOANS_PROCESSING_FEE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADDBACK_WEST_APPLIANCES', text: 'ADDBACK WEST APPLIANCES',hidden: parseFloat(data[data.length-1].inclusioN_ADDBACK_WEST_APPLIANCES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADDBACK_WEST_APPLIANCES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LEAVE_ALLOWANCE', text: 'LEAVE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LEAVE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LEAVE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SUPPLEMENTARY_INCENTIVES', text: 'SUPPLEMENTARY INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioN_SUPPLEMENTARY_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SUPPLEMENTARY_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ALLOWANCE_ADJUSTMENT', text: 'ALLOWANCE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioN_ALLOWANCE_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ALLOWANCE_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_PATERNITY_LEAVE_ADJUSTMENT', text: 'PATERNITY LEAVE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioN_PATERNITY_LEAVE_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_PATERNITY_LEAVE_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_SIL_ADJUSTMENT', text: 'SIL ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioN_SIL_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_SIL_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_POSITION_ALLOWANCE', text: 'POSITION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_POSITION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_POSITION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_GENERAL_CHARGES', text: 'GENERAL CHARGES',hidden: parseFloat(data[data.length-1].inclusioN_GENERAL_CHARGES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_GENERAL_CHARGES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_FOOD_ALLOWANCE', text: 'FOOD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_FOOD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_FOOD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE', text: 'NOT FOR BILLING - TRANSPORTATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_INCENTIVE', text: 'NOT FOR BILLING - INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_INCENTIVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_INCENTIVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_NOT_FOR_BILLING_SERVICE_CHARGE', text: 'NOT FOR BILLING - SERVICE CHARGE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_CHARGE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_CHARGE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ADD_COMMISSION', text: 'ADD COMMISSION',hidden: parseFloat(data[data.length-1].inclusioN_ADD_COMMISSION)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ADD_COMMISSION)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_MEAL_ALLOWANCE_CHARGE_TO_PHR', text: 'MEAL ALLOWANCE - CHARGE TO PHR',hidden: parseFloat(data[data.length-1].inclusioN_MEAL_ALLOWANCE_CHARGE_TO_PHR)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_MEAL_ALLOWANCE_CHARGE_TO_PHR)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_FOR_BILLING_HDMF_LOAN_INTERNAL', text: 'FOR BILLING - HDMF LOAN (INTERNAL)',hidden: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_HDMF_LOAN_INTERNAL)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_HDMF_LOAN_INTERNAL)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_FOR_BILLING_SSS_LOAN_INTERNAL', text: 'FOR BILLING - SSS LOAN (INTERNAL)',hidden: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_SSS_LOAN_INTERNAL)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_SSS_LOAN_INTERNAL)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_FOR_BILLING_MRP_INCENTIVES', text: 'FOR BILLING - MRP INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_MRP_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_FOR_BILLING_MRP_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_TAX_REFUND', text: 'TAX REFUND',hidden: parseFloat(data[data.length-1].inclusioN_TAX_REFUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_TAX_REFUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_DEMINIMIS_ALLOWANCE', text: 'DEMINIMIS ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_DEMINIMIS_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DEMINIMIS_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_DEMINIMIS_ALLOWANCE_NOOFDAYS', text: 'DEMINIMIS ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_DEMINIMIS_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_DEMINIMIS_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioN_OFFICIAL_BUSINESS_ALLOWANCE', text: 'OFFICIAL BUSINESS ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_OFFICIAL_BUSINESS_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_OFFICIAL_BUSINESS_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS', text: 'OFFICIAL BUSINESS ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioN_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },


            { dataField: 'inclusioN_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE', text: 'NOT FOR BILLING - SERVICE INCENTIVE LEAVE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_CBU_BILLED_TO_CLIENT', text: 'CBU BILLED TO CLIENT',hidden: parseFloat(data[data.length-1].inclusioN_CBU_BILLED_TO_CLIENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_CBU_BILLED_TO_CLIENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_YEAR_END_INCENTIVE_BONUS', text: 'YEAR END INCENTIVE BONUS',hidden: parseFloat(data[data.length-1].inclusioN_YEAR_END_INCENTIVE_BONUS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_YEAR_END_INCENTIVE_BONUS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioN_LHOL_DOD_OT_RATE_ALLOWANCE', text: 'LHOL DOD OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_DOD_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_DOD_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_ND_RATE_ALLOWANCE', text: 'ND RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_ND_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ND_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },            

            { dataField: 'inclusioN_ND_OT_RATE_ALLOWANCE', text: 'ND OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_ND_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ND_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LHOL_ND_OT_RATE_ALLOWANCE', text: 'LHOL ND OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_ND_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_ND_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioN_LHOL_ND_RATE_ALLOWANCE', text: 'LHOL ND RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_ND_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_ND_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },


            { dataField: 'totalInclusions', text: 'TOTAL INCLUSIONS',hidden: parseFloat(data[data.length-1].totalInclusions)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].totalInclusions)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_NRF', text: 'NRF',hidden: parseFloat(data[data.length-1].deductioN_NRF)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_NRF)===0 ? false : true,
            },
            { dataField: 'deductioN_AAP', text: 'AAP',hidden: parseFloat(data[data.length-1].deductioN_AAP)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_AAP)===0 ? false : true,
            },
            { dataField: 'deductioN_CBU', text: 'CBU',hidden: parseFloat(data[data.length-1].deductioN_CBU)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_CBU)===0 ? false : true,
            },
            { dataField: 'deductioN_BANK_FEES', text: 'BANK TRAN FEE',hidden: parseFloat(data[data.length-1].deductioN_BANK_FEES)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_BANK_FEES)===0 ? false : true,
            },
            { dataField: 'deductioN_PENALTY', text: 'PENALTY',hidden: parseFloat(data[data.length-1].deductioN_PENALTY)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_PENALTY)===0 ? false : true,
            },
            { dataField: 'deductioN_TMK', text: 'TRUE MONEY KIT',hidden: parseFloat(data[data.length-1].deductioN_TMK)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_TMK)===0 ? false : true,
            },
            { dataField: 'deductioN_PARAMOUNT_ID', text: 'PARAMOUNT ID',hidden: parseFloat(data[data.length-1].deductioN_PARAMOUNT_ID)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_PARAMOUNT_ID)===0 ? false : true,
            },
            { dataField: 'deductioN_HMO', text: 'HMO',hidden: parseFloat(data[data.length-1].deductioN_HMO)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioN_HMO)===0 ? false : true,
            },
            { dataField: 'deductioN_CASH_CARD', text: 'CASH CARD',hidden: parseFloat(data[data.length-1].deductioN_CASH_CARD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CASH_CARD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_BANK_TRANS', text: 'BANK TRANS',hidden: parseFloat(data[data.length-1].deductioN_BANK_TRANS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_BANK_TRANS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_ML_AMMENDMENT', text: 'ML AMMENDMENT',hidden: parseFloat(data[data.length-1].deductioN_ML_AMMENDMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_ML_AMMENDMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CANTEEN_STUB', text: 'CANTEEN STUB',hidden: parseFloat(data[data.length-1].deductioN_ML_AMMENDMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CANTEEN_STUB)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_UNIFORM', text: 'UNIFORM',hidden: parseFloat(data[data.length-1].deductioN_UNIFORM)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_UNIFORM)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CASH_ADVANCE', text: 'CASH ADVANCE',hidden: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_LOSS_DAMAGES', text: 'LOSS & DAMAGES',hidden: parseFloat(data[data.length-1].deductioN_LOSS_DAMAGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_LOSS_DAMAGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_OVER_PAYMENT', text: 'OVER PAYMENT',hidden: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_SHORTAGES', text: 'SHORTAGES',hidden: parseFloat(data[data.length-1].deductioN_SHORTAGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_SHORTAGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CREDIT_BREAD', text: 'CREDIT BREAD',hidden: parseFloat(data[data.length-1].deductioN_CREDIT_BREAD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CREDIT_BREAD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CREDIT_PORK', text: 'CREDIT PORK',hidden: parseFloat(data[data.length-1].deductioN_CREDIT_PORK)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CREDIT_PORK)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_MEDICAL', text: 'MEDICAL',hidden: parseFloat(data[data.length-1].deductioN_MEDICAL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_MEDICAL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_OTHER_CHARGES', text: 'OTHER CHARGES',hidden: parseFloat(data[data.length-1].deductioN_OTHER_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_OTHER_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_GADGET_LOAN', text: 'GADGET LOAN',hidden: parseFloat(data[data.length-1].deductioN_GADGET_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_GADGET_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_VOUCHER', text: 'VOUCHER',hidden: parseFloat(data[data.length-1].deductioN_VOUCHER)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_VOUCHER)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_DOUBLE_CREDIT', text: 'DOUBLE CREDIT',hidden: parseFloat(data[data.length-1].deductioN_DOUBLE_CREDIT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_DOUBLE_CREDIT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_FREIGHT', text: 'FREIGHT',hidden: parseFloat(data[data.length-1].deductioN_FREIGHT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_FREIGHT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_MCP', text: 'MCP',hidden: parseFloat(data[data.length-1].deductioN_MCP)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_MCP)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_PF', text: 'PROCESSING FEE',hidden: parseFloat(data[data.length-1].deductioN_PF)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PF)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_EBV', text: 'EXCESS BOOK VALUE',hidden: parseFloat(data[data.length-1].deductioN_EBV)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_EBV)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_2020', text: 'HMO 2020',hidden: parseFloat(data[data.length-1].deductioN_HMO_2020)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_2020)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_WEST_APPLIANCES', text: 'WEST APPLIANCES',hidden: parseFloat(data[data.length-1].deductioN_WEST_APPLIANCES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_WEST_APPLIANCES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_TRAVEL_FUND', text: 'TRAVEL FUND',hidden: parseFloat(data[data.length-1].deductioN_TRAVEL_FUND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_TRAVEL_FUND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_INVENTORY_VARIANCE', text: 'INVENTORY VARIANCE',hidden: parseFloat(data[data.length-1].deductioN_INVENTORY_VARIANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_INVENTORY_VARIANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_OLD', text: 'HMO OLD',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_BIKE_LOAN', text: 'BIKE LOAN',hidden: parseFloat(data[data.length-1].deductioN_BIKE_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_BIKE_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_MOBILE_CHARGES', text: 'MOBILE CHARGES',hidden: parseFloat(data[data.length-1].deductioN_MOBILE_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_MOBILE_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CAR_FEE', text: 'CAR FEE',hidden: parseFloat(data[data.length-1].deductioN_CAR_FEE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CAR_FEE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CP_LOAN_CHARGES', text: 'CP LOAN CHARGES',hidden: parseFloat(data[data.length-1].deductioN_CP_LOAN_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CP_LOAN_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_CURRENT', text: 'HMO CURRENT',hidden: parseFloat(data[data.length-1].deductioN_HMO_CURRENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_CURRENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CASH_BOND', text: 'CASH BOND',hidden: parseFloat(data[data.length-1].deductioN_CASH_BOND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CASH_BOND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_AAP_CHARGES', text: 'AAP CHARGES',hidden: parseFloat(data[data.length-1].deductioN_AAP_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_AAP_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HDMF_MP1', text: 'HDMF MP1',hidden: parseFloat(data[data.length-1].deductioN_HDMF_MP1)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HDMF_MP1)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioN_PHIC_ONEPERCENT', text: 'PHIC 1%',hidden: parseFloat(data[data.length-1].deductioN_PHIC_ONEPERCENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PHIC_ONEPERCENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioN_HMO_OLD_2020', text: 'HMO OLD 2020',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD_2020)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD_2020)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioN_HMO_OLD_2021', text: 'HMO OLD 2021',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD_2021)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD_2021)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioN_HMO_OLD_JAN_OCT_2022', text: 'HMO OLD JAN-OCT 2022',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD_JAN_OCT_2022)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD_JAN_OCT_2022)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioN_HMO_CURRENT_MONTH_NEW', text: 'HMO CURRENT MONTH NEW',hidden: parseFloat(data[data.length-1].deductioN_HMO_CURRENT_MONTH_NEW)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_CURRENT_MONTH_NEW)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_ASSESSMENT_CHARGES', text: 'ASSESSMENT CHARGES',hidden: parseFloat(data[data.length-1].deductioN_ASSESSMENT_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_ASSESSMENT_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_PALAWAN_CHARGES', text: 'PALAWAN CHARGES',hidden: parseFloat(data[data.length-1].deductioN_PALAWAN_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PALAWAN_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_PILFERAGE_CHARGE', text: 'PILFERAGE CHARGE',hidden: parseFloat(data[data.length-1].deductioN_PILFERAGE_CHARGE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PILFERAGE_CHARGE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_RAFLE_TICKET', text: 'RAFFLE TICKET',hidden: parseFloat(data[data.length-1].deductioN_RAFLE_TICKET)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_RAFLE_TICKET)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_MC_EXCESS_BOOK_VALUE', text: 'MC-EXCESS BOOK VALUE',hidden: parseFloat(data[data.length-1].deductioN_MC_EXCESS_BOOK_VALUE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_MC_EXCESS_BOOK_VALUE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_MC_PROCESSING_FEE', text: 'MC-PROCESSING FEE',hidden: parseFloat(data[data.length-1].deductioN_MC_PROCESSING_FEE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_MC_PROCESSING_FEE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_SSS_CONTRI_ADJUSTMENT', text: 'SSS CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioN_SSS_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_SSS_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HDMF_CONTRI_ADJUSTMENT', text: 'HDMF CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioN_HDMF_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HDMF_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_PHIC_CONTRI_ADJUSTMENT', text: 'PHIC CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioN_PHIC_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PHIC_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_OVER_PAYMENT_MATERNITY_CLAIMS', text: 'OVER PAYMENT-MATERNITY CLAIMS',hidden: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT_MATERNITY_CLAIMS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT_MATERNITY_CLAIMS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_GENERALI', text: 'HMO-GENERALI',hidden: parseFloat(data[data.length-1].deductioN_HMO_GENERALI)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_GENERALI)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_ADVANCES_TO_EMPLOYEES_RF', text: 'ADVANCES TO EMPLOYEES-RF',hidden: parseFloat(data[data.length-1].deductioN_ADVANCES_TO_EMPLOYEES_RF)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_ADVANCES_TO_EMPLOYEES_RF)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_RECLASS', text: 'HMO-RECLASS',hidden: parseFloat(data[data.length-1].deductioN_HMO_RECLASS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_RECLASS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CASH_ADVANCE_RECLASS', text: 'CASH ADVANCE-RECLASS',hidden: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE_RECLASS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE_RECLASS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_RENTAL_ADV', text: 'RENTAL-ADV',hidden: parseFloat(data[data.length-1].deductioN_RENTAL_ADV)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_RENTAL_ADV)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_NEW87', text: 'HMO-NEW',hidden: parseFloat(data[data.length-1].deductioN_HMO_NEW87)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_NEW87)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_OLD88', text: 'HMO-OLD',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD88)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD88)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_OLD_RECLASS89', text: 'HMO-OLD RECLASS',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD_RECLASS89)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD_RECLASS89)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_NEW90', text: 'HMO-NEW',hidden: parseFloat(data[data.length-1].deductioN_HMO_NEW90)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_NEW90)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_OLD91', text: 'HMO-OLD',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD91)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD91)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HMO_OLD_RECLASS92', text: 'HMO-OLD RECLASS',hidden: parseFloat(data[data.length-1].deductioN_HMO_OLD_RECLASS92)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HMO_OLD_RECLASS92)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_OVER_PAYMENT_WOUT_ATD', text: 'OVER PAYMENT-W/OUT ATD',hidden: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT_WOUT_ATD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_OVER_PAYMENT_WOUT_ATD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_PURCHASED_ITEMS', text: 'PURCHASED ITEM/S',hidden: parseFloat(data[data.length-1].deductioN_PURCHASED_ITEMS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_PURCHASED_ITEMS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_CASH_ADVANCE_CLIENT', text: 'CASH ADVANCE-CLIENT',hidden: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_CASH_ADVANCE_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_SSS_SALARY_LOAN_ADJ', text: 'SSS SALARY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioN_SSS_SALARY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_SSS_SALARY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_SSS_CALAMITY_LOAN_ADJ', text: 'SSS CALAMITY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioN_SSS_CALAMITY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_SSS_CALAMITY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HDMF_SALARY_LOAN_ADJ', text: 'HDMF SALARY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioN_HDMF_SALARY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HDMF_SALARY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_HDMF_CALAMITY_LOAN_ADJ', text: 'HDMF CALAMITY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioN_HDMF_CALAMITY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_HDMF_CALAMITY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_UNIFORM_CLIENT', text: 'UNIFORM-CLIENT',hidden: parseFloat(data[data.length-1].deductioN_UNIFORM_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_UNIFORM_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioN_LOSS_AND_DAMAGES_CLIENT', text: 'LOSS AND DAMAGES-CLIENT',hidden: parseFloat(data[data.length-1].deductioN_LOSS_AND_DAMAGES_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioN_LOSS_AND_DAMAGES_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'totalDeductions', text: 'TOTAL DEDUCTIONS',hidden: parseFloat(data[data.length-1].totalDeductions)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].totalDeductions)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },

            { dataField: 'totalGross', text: 'TOTAL GROSS PAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: true,
            },

            { dataField: 'e13TH_MONTH', text: '13TH MONTH',hidden: parseFloat(data[data.length-1].e13TH_MONTH)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].e13TH_MONTH)===0 ? false : true,
            },
            { dataField: 'colA_RATE', text: 'COLA RATE',hidden: parseFloat(data[data.length-1].colA_RATE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].colA_RATE)===0 ? false : true,
            },
            { dataField: 'seA_RATE', text: 'SEA RATE',hidden: parseFloat(data[data.length-1].seA_RATE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].seA_RATE)===0 ? false : true,
            },
            { dataField: 'sil', text: 'SIL',hidden: parseFloat(data[data.length-1].sil)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].sil)===0 ? false : true,
            },
            { dataField: 'sp', text: 'SEPARATION PAY',hidden: parseFloat(data[data.length-1].sp)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].sp)===0 ? false : true,
            },
            { dataField: 'sP1', text: 'SEPARATION PAY 1',hidden: parseFloat(data[data.length-1].sP1)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].sP1)===0 ? false : true,
            },
            { dataField: 'ssS_ER', text: 'SSS ER',hidden: parseFloat(data[data.length-1].ssS_ER)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].ssS_ER)===0 ? false : true,
            },
            { dataField: 'ssS_EC', text: 'SSS EC',hidden: parseFloat(data[data.length-1].ssS_EC)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].ssS_EC)===0 ? false : true,
            },
            { dataField: 'phiC_ER', text: 'PHIC ER',hidden: parseFloat(data[data.length-1].phiC_ER)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].phiC_ER)===0 ? false : true,
            },
            { dataField: 'hdmF_ER', text: 'HDMF ER',hidden: parseFloat(data[data.length-1].hdmF_ER)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].hdmF_ER)===0 ? false : true,
            },

            { dataField: 'subTotal', text: 'SUB TOTAL',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: true,
            },
            { dataField: 'adminFee', text: 'ADMIN COST',hidden: parseFloat(data[data.length-1].adminFee)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].adminFee)===0 ? false : true,
            },
            
            { dataField: 'exclusioN_SSS_EE', text: 'SSS EE',hidden: parseFloat(data[data.length-1].exclusioN_SSS_EE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_SSS_EE)===0 ? false : true,
            },
            { dataField: 'exclusioN_PHIC_EE', text: 'PHIC EE',hidden: parseFloat(data[data.length-1].exclusioN_PHIC_EE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_PHIC_EE)===0 ? false : true,
            },
            { dataField: 'exclusioN_HDMF_EE', text: 'HDMF EE',hidden: parseFloat(data[data.length-1].exclusioN_HDMF_EE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_HDMF_EE)===0 ? false : true,
            },
            { dataField: 'exclusioN_SSS_LOAN', text: 'SSS SALARY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_SSS_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_SSS_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_HDMF_LOAN', text: 'HDMF SALARY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_HDMF_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_HDMF_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_SSSCALAMITY_LOAN', text: 'SSS CALAMITY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_SSSCALAMITY_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_SSSCALAMITY_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_HDMFCALAMITY_LOAN', text: 'HDMF CALAMITY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_HDMFCALAMITY_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_HDMFCALAMITY_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_VACATION_LEAVE', text: 'VACATION LEAVE',hidden: parseFloat(data[data.length-1].exclusioN_VACATION_LEAVE)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_VACATION_LEAVE)===0 ? false : true,
            },
            { dataField: 'exclusioN_COOP_LOAN', text: 'COOP LOAN',hidden: parseFloat(data[data.length-1].exclusioN_COOP_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_COOP_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_EMERGENCY_LOAN', text: 'EMERGENCY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_EMERGENCY_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_EMERGENCY_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_MC_LOAN', text: 'MC LOAN',hidden: parseFloat(data[data.length-1].exclusioN_MC_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_MC_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_GADGET_LOAN', text: 'GADGET LOAN',hidden: parseFloat(data[data.length-1].exclusioN_GADGET_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_GADGET_LOAN)===0 ? false : true,
            },
            { dataField: 'exclusioN_COOP_CALAMITY_LOAN', text: 'COOP CALAMITY LOAN',hidden: parseFloat(data[data.length-1].exclusioN_COOP_CALAMITY_LOAN)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].exclusioN_COOP_CALAMITY_LOAN)===0 ? false : true,
            },
            { dataField: 'inclusioneX_SPF_NOOFDAYS', text: 'SKILLS PAY FX NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_SPF_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SPF_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SPF_AMOUNT', text: 'SKILLS PAY FX',hidden: parseFloat(data[data.length-1].inclusioneX_SPF_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SPF_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_MA_NOOFDAYS', text: 'MEAL ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_MA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_MA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MA_AMOUNT', text: 'MEAL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_MA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_MA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioneX_DA_NOOFDAYS', text: 'ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_DA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_DA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DA_AMOUNT', text: 'ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_DA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
        
            { dataField: 'inclusioneX_SPA_NOOFDAYS', text: 'SKILLS PAY ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_SPA_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SPA_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SPA_AMOUNT', text: 'SKILLS PAY ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SPA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SPA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_TL_NOOFDAYS', text: 'TEAM LEADER ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_TL_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_TL_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_TL_AMOUNT', text: 'TEAM LEADER ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_TL_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_TL_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_HP_NOOFDAYS', text: 'HAZARD PAY NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_HP_NOOFDAYS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_HP_NOOFDAYS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_HP_AMOUNT', text: 'HAZARD PAY',hidden: parseFloat(data[data.length-1].inclusioneX_HP_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_HP_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ALLOWANCE_AMOUNT', text: 'ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_ALLOWANCE_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_ALLOWANCE_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SL_AMOUNT', text: 'SHIFT LEADER',hidden: parseFloat(data[data.length-1].inclusioneX_SL_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SL_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_GM_AMOUNT', text: 'GROSS MARGIN',hidden: parseFloat(data[data.length-1].inclusioneX_GM_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_GM_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SG_AMOUNT', text: 'SALES GROWTH',hidden: parseFloat(data[data.length-1].inclusioneX_SG_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SG_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_PA_AMOUNT', text: 'PERFECT ATTENDANCE',hidden: parseFloat(data[data.length-1].inclusioneX_PA_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_PA_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LBC_AMOUNT', text: 'LBC',hidden: parseFloat(data[data.length-1].inclusioneX_LBC_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_LBC_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_BDAY_AMOUNT', text: 'BIRTHDAY',hidden: parseFloat(data[data.length-1].inclusioneX_BDAY_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_BDAY_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_TRANSPO_AMOUNT', text: 'TRANSPORTATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_TRANSPO_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_TRANSPO_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_WER_AMOUNT', text: 'WEEKLY EXPENSE REPORT',hidden: parseFloat(data[data.length-1].inclusioneX_WER_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_WER_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LOAD_AMOUNT', text: 'LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LOAD_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_LOAD_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MRP_AMOUNT', text: 'MRP INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioneX_MRP_AMOUNT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_MRP_AMOUNT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_OPUP_ADJ', text: 'ADJ (OP / UP)',hidden: parseFloat(data[data.length-1].inclusioneX_OPUP_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_OPUP_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SERVICE_CHARGED', text: 'SERVICE CHARGE',hidden: parseFloat(data[data.length-1].inclusioneX_SERVICE_CHARGED)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SERVICE_CHARGED)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_COOP_LOAN', text: 'ADDBACK COOP LOAN',hidden: parseFloat(data[data.length-1].inclusioneX_COOP_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_COOP_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SSS_LOAN', text: 'ADDBACK SSS LOAN',hidden: parseFloat(data[data.length-1].inclusioneX_SSS_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SSS_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_HDMF_LOAN', text: 'ADDBACK HDMF LOAN',hidden: parseFloat(data[data.length-1].inclusioneX_HDMF_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_HDMF_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SETUP_ALLOWANCE', text: 'SET UP ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SETUP_ALLOWANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SETUP_ALLOWANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_RATE_ADJUSTMENT', text: 'RATE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioneX_RATE_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_RATE_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_UNDER_PAYMENT', text: 'UNDER PAYMENT (FOR BILLING)',hidden: parseFloat(data[data.length-1].inclusioneX_UNDER_PAYMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_UNDER_PAYMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_UNDER_PAYMENT1', text: 'UNDER PAYMENT (NOT FOR BILLING)',hidden: parseFloat(data[data.length-1].inclusioneX_UNDER_PAYMENT1)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_UNDER_PAYMENT1)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_CREDITBREAD', text: 'ADDBACK CREDIT BREAD',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CREDITBREAD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CREDITBREAD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_CREDITPORK', text: 'ADDBACK CREDIT PORK',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CREDITPORK)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CREDITPORK)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DIVIDEND_ADDPAYROLL', text: 'DIVIDEND (ADD TO PAYROLL)',hidden: parseFloat(data[data.length-1].inclusioneX_DIVIDEND_ADDPAYROLL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_DIVIDEND_ADDPAYROLL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_13TH_ADDPAYROLL', text: '13TH MONTH (ADD TO PAYROLL)',hidden: parseFloat(data[data.length-1].inclusioneX_13TH_ADDPAYROLL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_13TH_ADDPAYROLL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MC_RENTAL', text: 'MC RENTAL',hidden: parseFloat(data[data.length-1].inclusioneX_MC_RENTAL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_MC_RENTAL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SA_INCENTIVE', text: 'SA INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioneX_SA_INCENTIVE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SA_INCENTIVE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SENIORITY_PAY', text: 'SENIORITY PAY',hidden: parseFloat(data[data.length-1].inclusioneX_SENIORITY_PAY)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].inclusioneX_SENIORITY_PAY)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_RICE_SUBSIDY', text: 'RICE SUBSIDY',hidden: parseFloat(data[data.length-1].inclusioneX_RICE_SUBSIDY)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_RICE_SUBSIDY)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioneX_MC_RENTAL_ADJ', text: 'MC RENTAL ADJ',hidden: parseFloat(data[data.length-1].inclusioneX_MC_RENTAL_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_MC_RENTAL_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MEAL_ALLOW_ADJ', text: 'MEAL ALLOWANCE ADJ',hidden: parseFloat(data[data.length-1].inclusioneX_MEAL_ALLOW_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_MEAL_ALLOW_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_OT_ADJ', text: 'OT ADJ',hidden: parseFloat(data[data.length-1].inclusioneX_OT_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_OT_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MH_ADJ', text: 'MH ADJ',hidden: parseFloat(data[data.length-1].inclusioneX_MH_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_MH_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_INCENTIVES', text: 'INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioneX_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_HMO', text: 'ADDBACK HMO',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_HMO)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_HMO)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_CBU', text: 'ADDBACK CBU',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CBU)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_CBU)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_WHSE_INCENTIVES', text: 'WHSE INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioneX_WHSE_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_WHSE_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_HOUSING_ALLOWANCE', text: 'HOUSING ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_HOUSING_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_HOUSING_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DATA_ALLOWANCE', text: 'DATA ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DATA_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DATA_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_SSS', text: 'ADDBACK SSS',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_SSS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_SSS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_PHIC', text: 'ADDBACK PHIC',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_PHIC)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_PHIC)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_HDMF', text: 'ADDBACK HDMF',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_HDMF)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_HDMF)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_INCENTIVE_ADJ', text: 'INCENTIVE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioneX_INCENTIVE_ADJ)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_INCENTIVE_ADJ)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SVC_INC_LEAVE', text: 'SERVICE INCENTIVE LEAVE',hidden: parseFloat(data[data.length-1].inclusioneX_SVC_INC_LEAVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SVC_INC_LEAVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_13TH_MONTH', text: '13TH MONTH',hidden: parseFloat(data[data.length-1].inclusioneX_13TH_MONTH)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_13TH_MONTH)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MC_PLAN', text: 'MC PLAN',hidden: parseFloat(data[data.length-1].inclusioneX_MC_PLAN)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_MC_PLAN)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SPOT', text: 'SPOT & UAT OT',hidden: parseFloat(data[data.length-1].inclusioneX_SPOT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SPOT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_RATE_ALLOWANCE', text: 'RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_OT_RATE_ALLOWANCE', text: 'OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DOD_RATE_ALLOWANCE', text: 'DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DOD_OT_RATE_ALLOWANCE', text: 'DOD OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DOD_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DOD_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_SH_RATE_ALLOWANCE', text: 'SH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LH_RATE_ALLOWANCE', text: 'LH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_LH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LHOT_RATE_ALLOWANCE', text: 'LHOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LHOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_LHOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SHOT_RATE_ALLOWANCE', text: 'SHOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SHOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SHOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_PRODUCT_CONSULTANT_LOAD_ALLOWANCE', text: 'PRODUCT CONSULTANT LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_PRODUCT_CONSULTANT_LOAD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_PRODUCT_CONSULTANT_LOAD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SH_DOD_RATE_ALLOWANCE', text: 'SH DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SH_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SH_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SH_DODOT_RATE_ALLOWANCE', text: 'SH DODOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_SH_DODOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SH_DODOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LH_DOD_RATE_ALLOWANCE', text: 'LH DOD RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LH_DOD_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_LH_DOD_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LH_DODOT_RATE_ALLOWANCE', text: 'LH DODOT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LH_DODOT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_LH_DODOT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_TL_ALLOWANCE', text: 'NOT FOR BILLING - TL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_TL_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_TL_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_LOAD_ALLOWANCE', text: 'NOT FOR BILLING - LOAD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_LOAD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_LOAD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_COMMUNICATION_ALLOWANCE', text: 'COMMUNICATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_COMMUNICATION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_COMMUNICATION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_13TH_MONTH_RATE_ALLOWANCE', text: '13TH MONTH RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_13TH_MONTH_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_13TH_MONTH_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_PERFECT_ATTENDANCE', text: 'NOT FOR BILLING - PERFECT ATTENDANCE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_PERFECT_ATTENDANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_PERFECT_ATTENDANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE', text: 'NOT FOR BILLING - MEAL ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS', text: 'NOT FOR BILLING - MEAL ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_MEAL_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_14TH_MONTH', text: '14TH MONTH',hidden: parseFloat(data[data.length-1].inclusioneX_14TH_MONTH)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_14TH_MONTH)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_CASH_ADVANCE', text: 'CASH ADVANCE',hidden: parseFloat(data[data.length-1].inclusioneX_CASH_ADVANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_CASH_ADVANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_REVOLVING_FUND', text: 'REVOLVING FUND',hidden: parseFloat(data[data.length-1].inclusioneX_REVOLVING_FUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_REVOLVING_FUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DIVIDEND', text: 'DIVIDEND',hidden: parseFloat(data[data.length-1].inclusioneX_DIVIDEND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DIVIDEND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_OJT_AND_OBT', text: 'OJT AND OBT',hidden: parseFloat(data[data.length-1].inclusioneX_OJT_AND_OBT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_OJT_AND_OBT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_PURCHASED_ITEM', text: 'ADDBACK PURCHASED ITEM',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_PURCHASED_ITEM)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_PURCHASED_ITEM)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_TRAVEL_FUND', text: 'ADDBACK TRAVEL FUND',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_TRAVEL_FUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_TRAVEL_FUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_LOSS_AND_DAMAGES', text: 'ADDBACK LOSS AND DAMAGES',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_LOSS_AND_DAMAGES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_LOSS_AND_DAMAGES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_MEMBERSHIP_FEE_AAP_NRF', text: 'ADDBACK MEMBERSHIP FEE(AAP/NRF)',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_MEMBERSHIP_FEE_AAP_NRF)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_MEMBERSHIP_FEE_AAP_NRF)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_MC_LOANS_PROCESSING_FEE', text: 'ADDBACK MC LOANS/PROCESSING FEE',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_MC_LOANS_PROCESSING_FEE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_MC_LOANS_PROCESSING_FEE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADDBACK_WEST_APPLIANCES', text: 'ADDBACK WEST APPLIANCES',hidden: parseFloat(data[data.length-1].inclusioneX_ADDBACK_WEST_APPLIANCES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADDBACK_WEST_APPLIANCES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LEAVE_ALLOWANCE', text: 'LEAVE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_LEAVE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_LEAVE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SUPPLEMENTARY_INCENTIVES', text: 'SUPPLEMENTARY INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioneX_SUPPLEMENTARY_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SUPPLEMENTARY_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_ALLOWANCE_ADJUSTMENT', text: 'ALLOWANCE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioneX_ALLOWANCE_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ALLOWANCE_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_PATERNITY_LEAVE_ADJUSTMENT', text: 'PATERNITY LEAVE ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioneX_PATERNITY_LEAVE_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_PATERNITY_LEAVE_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_SIL_ADJUSTMENT', text: 'SIL ADJUSTMENT',hidden: parseFloat(data[data.length-1].inclusioneX_SIL_ADJUSTMENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_SIL_ADJUSTMENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_POSITION_ALLOWANCE', text: 'POSITION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_POSITION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_POSITION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_GENERAL_CHARGES', text: 'GENERAL CHARGES',hidden: parseFloat(data[data.length-1].inclusioneX_GENERAL_CHARGES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_GENERAL_CHARGES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_FOOD_ALLOWANCE', text: 'FOOD ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_FOOD_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_FOOD_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE', text: 'NOT FOR BILLING - TRANSPORTATION ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_TRANSPORTATION_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_INCENTIVE', text: 'NOT FOR BILLING - INCENTIVE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_INCENTIVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_INCENTIVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_NOT_FOR_BILLING_SERVICE_CHARGE', text: 'NOT FOR BILLING - SERVICE CHARGE',hidden: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_SERVICE_CHARGE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_NOT_FOR_BILLING_SERVICE_CHARGE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ADD_COMMISSION', text: 'ADD COMMISSION',hidden: parseFloat(data[data.length-1].inclusioneX_ADD_COMMISSION)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_ADD_COMMISSION)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_MEAL_ALLOWANCE_CHARGE_TO_PHR', text: 'MEAL ALLOWANCE - CHARGE TO PHR',hidden: parseFloat(data[data.length-1].inclusioneX_MEAL_ALLOWANCE_CHARGE_TO_PHR)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_MEAL_ALLOWANCE_CHARGE_TO_PHR)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_FOR_BILLING_HDMF_LOAN_INTERNAL', text: 'FOR BILLING - HDMF LOAN (INTERNAL)',hidden: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_HDMF_LOAN_INTERNAL)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_HDMF_LOAN_INTERNAL)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_FOR_BILLING_SSS_LOAN_INTERNAL', text: 'FOR BILLING - SSS LOAN (INTERNAL)',hidden: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_SSS_LOAN_INTERNAL)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_SSS_LOAN_INTERNAL)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_FOR_BILLING_MRP_INCENTIVES', text: 'FOR BILLING - MRP INCENTIVES',hidden: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_MRP_INCENTIVES)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_FOR_BILLING_MRP_INCENTIVES)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_TAX_REFUND', text: 'TAX REFUND',hidden: parseFloat(data[data.length-1].inclusioneX_TAX_REFUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_TAX_REFUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_TAX_REFUND', text: 'TAX REFUND',hidden: parseFloat(data[data.length-1].inclusioneX_TAX_REFUND)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_TAX_REFUND)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_DEMINIMIS_ALLOWANCE', text: 'DEMINIMIS ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_DEMINIMIS_ALLOWANCE_NOOFDAYS', text: 'DEMINIMIS ALLOWANCE NO OF DAYS',hidden: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'inclusioneX_OFFICIAL_BUSINESS_ALLOWANCE', text: 'DEMINIMIS ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_DEMINIMIS_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS', text: 'OFFICIAL BUSINESS ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioneX_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioneX_OFFICIAL_BUSINESS_ALLOWANCE_NOOFDAYS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },


            { dataField: 'inclusioneX_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE', text: 'NOT FOR BILLING - SERVICE INCENTIVE LEAVE',hidden: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_NOT_FOR_BILLING_SERVICE_INCENTIVE_LEAVE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_CBU_BILLED_TO_CLIENT', text: 'CBU BILLED TO CLIENT',hidden: parseFloat(data[data.length-1].inclusioN_CBU_BILLED_TO_CLIENT)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_CBU_BILLED_TO_CLIENT)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_YEAR_END_INCENTIVE_BONUS', text: 'YEAR END INCENTIVE BONUS',hidden: parseFloat(data[data.length-1].inclusioN_YEAR_END_INCENTIVE_BONUS)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_YEAR_END_INCENTIVE_BONUS)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'inclusioneX_LHOL_DOD_OT_RATE_ALLOWANCE', text: 'LHOL DOD OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_DOD_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_DOD_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_ND_RATE_ALLOWANCE', text: 'ND RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_ND_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ND_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },            

            { dataField: 'inclusioneX_ND_OT_RATE_ALLOWANCE', text: 'ND OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_ND_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_ND_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LHOL_ND_OT_RATE_ALLOWANCE', text: 'LHOL ND OT RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_ND_OT_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_ND_OT_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'inclusioneX_LHOL_ND_RATE_ALLOWANCE', text: 'LHOL ND RATE ALLOWANCE',hidden: parseFloat(data[data.length-1].inclusioN_LHOL_ND_RATE_ALLOWANCE)===0 ? true : false,
            csvExport: parseFloat(data[data.length-1].inclusioN_LHOL_ND_RATE_ALLOWANCE)===0 ? false : true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            style:{textAlign:'right',fontSize:'10px'}
            },


            { dataField: 'deductioneX_NRF', text: 'NRF',hidden: parseFloat(data[data.length-1].deductioneX_NRF)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_NRF)===0 ? false : true,
            },
            { dataField: 'deductioneX_AAP', text: 'AAP',hidden: parseFloat(data[data.length-1].deductioneX_AAP)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_AAP)===0 ? false : true,
            },
            { dataField: 'deductioneX_CBU', text: 'CBU',hidden: parseFloat(data[data.length-1].deductioneX_CBU)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_CBU)===0 ? false : true,
            },
            { dataField: 'deductioneX_BANK_FEES', text: 'BANK TRAN FEE',hidden: parseFloat(data[data.length-1].deductioneX_BANK_FEES)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_BANK_FEES)===0 ? false : true,
            },
            { dataField: 'deductioneX_PENALTY', text: 'PENALTY',hidden: parseFloat(data[data.length-1].deductioneX_PENALTY)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_PENALTY)===0 ? false : true,
            },
            { dataField: 'deductioneX_TMK', text: 'TRUE MONEY KIT',hidden: parseFloat(data[data.length-1].deductioneX_TMK)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_TMK)===0 ? false : true,
            },
            { dataField: 'deductioneX_PARAMOUNT_ID', text: 'PARAMOUNT ID',hidden: parseFloat(data[data.length-1].deductioneX_PARAMOUNT_ID)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_PARAMOUNT_ID)===0 ? false : true,
            },
            { dataField: 'deductioneX_HMO', text: 'HMO',hidden: parseFloat(data[data.length-1].deductioneX_HMO)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO)===0 ? false : true,
            },
            { dataField: 'deductioneX_CASH_CARD', text: 'CASH CARD',hidden: parseFloat(data[data.length-1].deductioneX_CASH_CARD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CASH_CARD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_BANK_TRANS', text: 'BANK TRANS',hidden: parseFloat(data[data.length-1].deductioneX_BANK_TRANS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_BANK_TRANS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_ML_AMMENDMENT', text: 'ML AMMENDMENT',hidden: parseFloat(data[data.length-1].deductioneX_ML_AMMENDMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_ML_AMMENDMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CANTEEN_STUB', text: 'CANTEEN STUB',hidden: parseFloat(data[data.length-1].deductioneX_ML_AMMENDMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CANTEEN_STUB)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_UNIFORM', text: 'UNIFORM',hidden: parseFloat(data[data.length-1].deductioneX_UNIFORM)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_UNIFORM)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CASH_ADVANCE', text: 'CASH ADVANCE',hidden: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_LOSS_DAMAGES', text: 'LOSS & DAMAGES',hidden: parseFloat(data[data.length-1].deductioneX_LOSS_DAMAGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_LOSS_DAMAGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_OVER_PAYMENT', text: 'OVER PAYMENT',hidden: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_SHORTAGES', text: 'SHORTAGES',hidden: parseFloat(data[data.length-1].deductioneX_SHORTAGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_SHORTAGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CREDIT_BREAD', text: 'CREDIT BREAD',hidden: parseFloat(data[data.length-1].deductioneX_CREDIT_BREAD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CREDIT_BREAD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CREDIT_PORK', text: 'CREDIT PORK',hidden: parseFloat(data[data.length-1].deductioneX_CREDIT_PORK)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CREDIT_PORK)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_MEDICAL', text: 'MEDICAL',hidden: parseFloat(data[data.length-1].deductioneX_MEDICAL)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_MEDICAL)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_OTHER_CHARGES', text: 'OTHER CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_OTHER_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_OTHER_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_GADGET_LOAN', text: 'GADGET LOAN',hidden: parseFloat(data[data.length-1].deductioneX_GADGET_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_GADGET_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_VOUCHER', text: 'VOUCHER',hidden: parseFloat(data[data.length-1].deductioneX_VOUCHER)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_VOUCHER)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_DOUBLE_CREDIT', text: 'DOUBLE CREDIT',hidden: parseFloat(data[data.length-1].deductioneX_DOUBLE_CREDIT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_DOUBLE_CREDIT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_FREIGHT', text: 'FREIGHT',hidden: parseFloat(data[data.length-1].deductioneX_FREIGHT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_FREIGHT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_MCP', text: 'MCP',hidden: parseFloat(data[data.length-1].deductioneX_MCP)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_MCP)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_PF', text: 'PROCESSING FEE',hidden: parseFloat(data[data.length-1].deductioneX_PF)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PF)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_EBV', text: 'EXCESS BOOK VALUE',hidden: parseFloat(data[data.length-1].deductioneX_EBV)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_EBV)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_2020', text: 'HMO 2020',hidden: parseFloat(data[data.length-1].deductioneX_HMO_2020)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_2020)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_WEST_APPLIANCES', text: 'WEST APPLIANCES',hidden: parseFloat(data[data.length-1].deductioneX_WEST_APPLIANCES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_WEST_APPLIANCES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_TRAVEL_FUND', text: 'TRAVEL FUND',hidden: parseFloat(data[data.length-1].deductioneX_TRAVEL_FUND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_TRAVEL_FUND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_INVENTORY_VARIANCE', text: 'INVENTORY VARIANCE',hidden: parseFloat(data[data.length-1].deductioneX_INVENTORY_VARIANCE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_INVENTORY_VARIANCE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_OLD', text: 'HMO OLD',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_BIKE_LOAN', text: 'BIKE LOAN',hidden: parseFloat(data[data.length-1].deductioneX_BIKE_LOAN)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_BIKE_LOAN)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_MOBILE_CHARGES', text: 'MOBILE CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_MOBILE_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_MOBILE_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CAR_FEE', text: 'CAR FEE',hidden: parseFloat(data[data.length-1].deductioneX_CAR_FEE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CAR_FEE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CP_LOAN_CHARGES', text: 'CP LOAN CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_CP_LOAN_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CP_LOAN_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_CURRENT', text: 'HMO CURRENT',hidden: parseFloat(data[data.length-1].deductioneX_HMO_CURRENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_CURRENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CASH_BOND', text: 'CASH BOND',hidden: parseFloat(data[data.length-1].deductioneX_CASH_BOND)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CASH_BOND)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_AAP_CHARGES', text: 'AAP CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_AAP_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_AAP_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HDMF_MP1', text: 'HDMF MP1',hidden: parseFloat(data[data.length-1].deductioneX_HDMF_MP1)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HDMF_MP1)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioneX_PHIC_ONEPERCENT', text: 'PHIC 1%',hidden: parseFloat(data[data.length-1].deductioneX_PHIC_ONEPERCENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PHIC_ONEPERCENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioneX_HMO_OLD_2020', text: 'HMO OLD 2020',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD_2020)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD_2020)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioneX_HMO_OLD_2021', text: 'HMO OLD 2021',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD_2021)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD_2021)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioneX_HMO_OLD_JAN_OCT_2022', text: 'HMO OLD JAN-OCT 2022',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD_JAN_OCT_2022)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD_JAN_OCT_2022)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
			{ dataField: 'deductioneX_HMO_CURRENT_MONTH_NEW', text: 'HMO CURRENT MONTH NEW',hidden: parseFloat(data[data.length-1].deductioneX_HMO_CURRENT_MONTH_NEW)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_CURRENT_MONTH_NEW)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_WORKERS_PERMIT', text: 'WORKERS PERMIT',hidden: parseFloat(data[data.length-1].deductioneX_WORKERS_PERMIT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_WORKERS_PERMIT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_ASSESSMENT_CHARGES', text: 'ASSESSMENT CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_ASSESSMENT_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_ASSESSMENT_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_PALAWAN_CHARGES', text: 'PALAWAN CHARGES',hidden: parseFloat(data[data.length-1].deductioneX_PALAWAN_CHARGES)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PALAWAN_CHARGES)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_PILFERAGE_CHARGE', text: 'PILFERAGE CHARGE',hidden: parseFloat(data[data.length-1].deductioneX_PILFERAGE_CHARGE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PILFERAGE_CHARGE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_RAFLE_TICKET', text: 'RAFFLE TICKET',hidden: parseFloat(data[data.length-1].deductioneX_RAFLE_TICKET)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_RAFLE_TICKET)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_MC_EXCESS_BOOK_VALUE', text: 'MC-EXCESS BOOK VALUE',hidden: parseFloat(data[data.length-1].deductioneX_MC_EXCESS_BOOK_VALUE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_MC_EXCESS_BOOK_VALUE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_MC_PROCESSING_FEE', text: 'MC-EXCESS BOOK VALUE',hidden: parseFloat(data[data.length-1].deductioneX_MC_PROCESSING_FEE)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_MC_PROCESSING_FEE)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_SSS_CONTRI_ADJUSTMENT', text: 'SSS CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioneX_SSS_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_SSS_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HDMF_CONTRI_ADJUSTMENT', text: 'HDMF CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioneX_HDMF_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HDMF_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_PHIC_CONTRI_ADJUSTMENT', text: 'PHIC CONTRI-ADJUSTMENT',hidden: parseFloat(data[data.length-1].deductioneX_PHIC_CONTRI_ADJUSTMENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PHIC_CONTRI_ADJUSTMENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_OVER_PAYMENT_MATERNITY_CLAIMS', text: 'OVER PAYMENT-MATERNITY CLAIMS',hidden: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT_MATERNITY_CLAIMS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT_MATERNITY_CLAIMS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_GENERALI', text: 'HMO-GENERALI',hidden: parseFloat(data[data.length-1].deductioneX_HMO_GENERALI)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_GENERALI)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_ADVANCES_TO_EMPLOYEES_RF', text: 'ADVANCES TO EMPLOYEES-RF',hidden: parseFloat(data[data.length-1].deductioneX_ADVANCES_TO_EMPLOYEES_RF)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_ADVANCES_TO_EMPLOYEES_RF)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_RECLASS', text: 'HMO-RECLASS',hidden: parseFloat(data[data.length-1].deductioneX_HMO_RECLASS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_RECLASS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CASH_ADVANCE_RECLASS', text: 'CASH ADVANCE-RECLASS',hidden: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE_RECLASS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE_RECLASS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_RENTAL_ADV', text: 'RENTAL-ADV',hidden: parseFloat(data[data.length-1].deductioneX_RENTAL_ADV)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_RENTAL_ADV)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_NEW87', text: 'HMO-NEW',hidden: parseFloat(data[data.length-1].deductioneX_HMO_NEW87)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_NEW87)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_OLD88', text: 'HMO-OLD',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD88)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD88)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_OLD_RECLASS89', text: 'HMO-OLD RECLASS',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD_RECLASS89)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD_RECLASS89)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_NEW90', text: 'HMO-NEW',hidden: parseFloat(data[data.length-1].deductioneX_HMO_NEW90)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_NEW90)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_OLD91', text: 'HMO-OLD',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD91)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD91)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HMO_OLD_RECLASS92', text: 'HMO-OLD RECLASS',hidden: parseFloat(data[data.length-1].deductioneX_HMO_OLD_RECLASS92)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HMO_OLD_RECLASS92)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_OVER_PAYMENT_WOUT_ATD', text: 'OVER PAYMENT-W/OUT ATD',hidden: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT_WOUT_ATD)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_OVER_PAYMENT_WOUT_ATD)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_PURCHASED_ITEMS', text: 'PURCHASED ITEM/S',hidden: parseFloat(data[data.length-1].deductioneX_PURCHASED_ITEMS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_PURCHASED_ITEMS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_CASH_ADVANCE_CLIENT', text: 'CASH ADVANCE-CLIENT',hidden: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_CASH_ADVANCE_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_SSS_SALARY_LOAN_ADJ', text: 'SSS SALARY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioneX_SSS_SALARY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_SSS_SALARY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_SSS_CALAMITY_LOAN_ADJ', text: 'SSS CALAMITY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioneX_SSS_CALAMITY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_SSS_CALAMITY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HDMF_SALARY_LOAN_ADJ', text: 'HDMF SALARY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioneX_HDMF_SALARY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HDMF_SALARY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_HDMF_CALAMITY_LOAN_ADJ', text: 'HDMF CALAMITY LOAN ADJ',hidden: parseFloat(data[data.length-1].deductioneX_HDMF_CALAMITY_LOAN_ADJ)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_HDMF_CALAMITY_LOAN_ADJ)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_UNIFORM_CLIENT', text: 'UNIFORM-CLIENT',hidden: parseFloat(data[data.length-1].deductioneX_UNIFORM_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_UNIFORM_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'deductioneX_LOSS_AND_DAMAGES_CLIENT', text: 'LOSS AND DAMAGES-CLIENT',hidden: parseFloat(data[data.length-1].deductioneX_LOSS_AND_DAMAGES_CLIENT)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].deductioneX_LOSS_AND_DAMAGES_CLIENT)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'vat', text: 'VAT',hidden: parseFloat(data[data.length-1].vat)===0 ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'},
                csvExport: true,
            },
            { dataField: 'grandTotal', text: 'GRAND TOTAL',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'},
                csvExport: true,
            },
            { dataField: 'remarks', text: 'REMARKS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'},
                csvExport: true,
            }]
    }
}

const NoserTable = ({ data, exportCSV, pageSize }) =>
{
    const selectRow = {
        mode: 'checkbox',
        style:{backgroundColor:'yellow', color:'#000',cursor:'pointer'},
        clickToSelect: true,
        hideSelectAll: true,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            //let isDisable=true
            data.map(function(item,i){
                if(item.id===row.id)
                {
                    item.isModified = isSelect ? "1" : "0"
                }
                //if (item.isDeleted=="1")
                //isDisable =  false
            })
            //this.setState({isDisable:isDisable})
        }
    };
    if(exportCSV==true){
        const col = BillingSummaryColumn(data)
        const { ExportCSVButton } = CSVExport;  
        return(
            <ToolkitProvider
                keyField="id"   
                data={ data }
                columns = { BillingSummaryColumn(data) }
                exportCSV={ {
                    fileName: "BillingSummary.csv",
                } }
                >
                {
                    props => (
                    <div>
                        <ExportCSVButton  disabled={data.length===0 ? true:false} className="noser-button btn btn-success" { ...props.csvProps }>Export</ExportCSVButton>
                        <Card.Header style={{marginTop:"3px"}}>Billing List</Card.Header>
                        <BootstrapTable
                            striped
                            hover
                            condensed
                            //pagination={ paginationFactory({sizePerPage:pageSize,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                            pagination={ paginationFactory({sizePerPageRenderer})}
                            keyField = "id"
                            data = { data }
                            noDataIndication={ () => <div>No record found.</div> }
                            columns = { col }
                            rowClasses="noser-table-row-class"
                            selectRow = { selectRow }
                        />
                    </div>
                    )
                }
            </ToolkitProvider>
            
        )
    }
    else
    {
        const col = BillingSummaryColumn(data)
        return(
            
            <div style={{fontSize:'8px'}}>    
                <BootstrapTable
                    striped
                    hover
                    condensed
                    //pagination={ paginationFactory({sizePerPage:pageSize,hideSizePerPage:false,hidePageListOnlyOnePage:false})}
                    pagination={ paginationFactory({sizePerPageRenderer})}
                    keyField = "id"
                    data = { data }
                    noDataIndication={ () => <div>No record found.</div> }
                    columns = { col }
                    rowClasses="noser-table-row-class no-checkbox"
                    selectRow = { selectRow }
                />
            </div>
        )
    }
}

export default class NoserGrid extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <NoserTable 
                data = {this.props.data}
                exportCSV = {this.props.exportCSV}
                pageSize = {this.props.pageSize}
            />
        )
    }
}
